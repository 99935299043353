@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.grid {
  display: grid;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* > * {
    font-family: "Poppins", cursive;
    margin: 0;
}

:root{
    --color-dark: #2C3131;
    --color-light: #F3F3F2;
}

#main{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
}

.board {
    text-align: center;
    padding: 1rem;
}

.board .leaderboard{
    margin-bottom: 1em;
}

.board .duration{
    display: flex;
    justify-content: center;
    gap: 1em;
}

.board .duration > button{
    border: 1px solid var(--color-light);
    padding: .5em 2em;
    border-radius: 50px;
    background-color: transparent;
    cursor: pointer;
}

.board .duration > button:hover{
    background-color: var(--color-dark);
    color: var(--color-light);
}

.board .duration > button.active{
  background-color: var(--color-dark);
  color: var(--color-light);
}

.active{
    background-color: var(--color-dark);
    color: var(--color-light);
}

#profile{
    /* display: flex; */

    margin-top: 3em;
}

#profile .flex{
    display: flex;
    justify-content: space-between;
    gap: 1em;
    text-align: left;
    margin-bottom: 2em;
}

#profile .flex .item{
    display: flex;
    align-items: center;
}

#profile .flex img{
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

#profile .flex .info{
   padding: 1em;
}

.text-dark{
    color: var(--color-dark);
}

.justify-between {
  justify-content: space-between;
}

iframe {
  pointer-events: none;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.feedback-body {
  background-color: rgb(197, 197, 197);
}

.container-feedback {
    width: 100%;
    background: lightgray;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 0px;
}

.w-10 {
  width: 5rem/* 40px */;
}

.h-10 {
  height: 5rem/* 40px */;
}

.AdminBox {
padding: 1rem;
position : relative;
} 

.logout-container {
  padding-top: 1rem;
  padding-left: 3rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 800px) {
.grid-cols-3{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.feedback-container {
  padding-left: 1rem;
  padding-right: 1rem;

}
} 

.nav-bar {
  width: 100%;
  /* background-color: rgb(240, 241, 186); */
  overflow: auto;
  border-bottom: #282c34 1px solid;
}
.nav-bar-button {
  float: left;
  all: unset;
  text-align: center;
  padding: 12px;
  color: black;
  text-decoration: none;
  font-size: 17px;
}

.nav-bar-button:hover {
  background-color: rgb(240, 241, 186);;
  /* color: white */
}

.field-change-container {
  display: flex;
  justify-content: center;
  /* width: 500px; */
  align-items: center;
  gap: 4rem;
}

.settings-container{
  text-align: -webkit-center;
  text-align: -moz-center;
}

.titular_batchadd {
  background-color: #f5f5f5;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  cursor: pointer
}

/* Add fancy css to the tables */
table {
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid rgb(145, 28, 28); */
  font-size: 18px;
}
thead {
  background-color: rgb(145, 28, 28);
  color: white;
}

tbody{
  background-color: rgb(255, 255, 255);
}

th, td {
  text-align: left;
  padding: 8px;
}
