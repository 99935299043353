@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grid {
  display: grid;
}

.col-span-2 {
  -ms-grid-column-span: span 2 / span 2;
  grid-column: span 2 / span 2;
}
/* .col-span-2 {
    grid-column: span 2 / span 2;
} */

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.justify-between {
  justify-content: space-between;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.p-2{
  padding: 0.5rem;
}

.p-4{
  padding: 1rem;
}

.absolute {
  position: absolute;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-full {
  border-radius: 9999px;
}

.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}

.w-50 {
  width: 12.5rem; /* 200px */
}

.h-50 {
  height: 12.5rem; /* 200px */
}
.relative {
  position: relative;
}

.p-4 {
  padding: 1rem; /* 40px */
}

.score-second {
  padding-left: 5rem;
  font-size: x-large;
}

#profile-tab-inner {
  padding-left: 2rem;
  background-color: rgb(191, 191, 191);
  border-radius: 80px;
}

.width-full {
  width: 100%;
}

.p-50 {
  padding: 12.5rem; /* 200px */
}

.justify-left {
  justify-items: flex-start;
}

.p-40 {
  padding: 10rem; /* 160px */
}
.feedback-container {
  padding-left: 8rem; /* 128px */
  padding-right: 8rem; /* 128px */
}

.card {
  background-color: #edf5ff !important;
}

.left-align {
  text-align: left;
    display: flex;
    justify-content: space-between;

}
.basic-typography {
  background-color: beige;
  border-radius: 10px;
}